<template>
  <div>
    <section class="page-title text-center mb-5">
      <div class="container">
        <div class="content-box">
          <h1>
            {{ $t("myreservations") }}
          </h1>
        </div>
      </div>
    </section>
    <div style="background-color: white; padding-top: 36px; padding-bottom: 150px">
      <div class="container">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col" style="width:100px">#</th>
              <th scope="col">{{ $t('First Name') }}</th>
              <th scope="col">{{ $t('Last Name') }}</th>


              <th scope="col">Address</th>
              <th scope="col">Zip</th>
              <th scope="col">city</th>
              <th scope="col">country</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{{ $codePadding(item.id) }}</th>
              <td>{{ item.fname }}</td>
              <td>{{ item.lname }}</td>

              <td>{{ item.address }}</td>
              <td>{{ item.zip }}</td>

              <td>{{ item.city }}</td>
              <td>{{ item.country }}</td>
            </tr>
          </tbody>
        </table>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">{{ $t('Payment Method') }}</th>
              <th scope="col">{{ $t('Massage') }}</th>
              <th scope="col">{{ $t('Plan') }}</th>

              <th scope="col">{{ $t('Day') }}</th>
              <th scope="col">{{ $t('Time') }}</th>
              <th scope="col">{{ $t('Masser') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-if="item.usersId">
                {{ item.paymentMethod }}
                -
                {{ item.usersId.paymentDone ? "And Done" : "But Not Complite" }}
              </td>
              <td v-if="item.massageId">{{ $getByLang(item.massageId.name) }}</td>

              <td v-if="item.pricesId">
                {{ $getByLang(item.pricesId.name) }} ({{ item.pricesId.price }} €)
              </td>

              <td>{{ $durationFormat(item.start) }}</td>
              <td>{{ $durationFormatStartToEnd(item.start, item.end) }}</td>
              <td>{{ item.adminsId.fullName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      item: {
        id: null,
        sexType: null,
        fname: null,
        lname: null,
        paymentMethod: null,
        paymentDone: false,
        address: null,
        zip: null,
        city: null,
        country: null,
        start: null,
        end: null,

        adminsId: {
          fullName: "test",
        },
      },
    };
  },

  methods: {},
  async created() {
    const id = this.$route.params.id;

    this.$http.get("appointments/" + id).then((res) => {
      this.item = res.data.data;

      const user = JSON.parse(localStorage.userKB);
      if (user.id != this.item.usersId.id) {
        window.location.href = 'https://kleopatramassage-beauty.com';
      }
    });
  },
};
</script>

<style></style>
